import firebase from 'firebase/app';

import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/functions';

const firebaseConfig = {
    apiKey: "AIzaSyB1bRXJvKkwI5skrvVGjZmjFwoUtvipv94",
    authDomain: "cme-plugins.firebaseapp.com",
    databaseURL: "https://cme-plugins.firebaseio.com",
    projectId: "cme-plugins",
    storageBucket: "cme-plugins.appspot.com",
    messagingSenderId: "6108896056",
    appId: "1:6108896056:web:bf9c9161027ca2e7787fcd"
  };

  firebase.initializeApp(firebaseConfig);

  export const auth = firebase.auth();

  export const db = firebase.firestore();

  export const firestore = firebase.firestore;

  export const functions = firebase.functions();

