import React, {useState} from 'react';

import './CreateQuestion.css'

import {db} from '../../services/firebase';
import CSVReader from 'react-csv-reader'


const CreateQuestion = (props) => {

    const [csvQuestions, setCsvQuestions] = useState([]);

    const [answers, setAnswers] = useState({});
    const [questionText, setQuestionText] = useState('');
    const [correctAnsw, setCorrectAnsw] = useState('');

    const [resId, setResId] = useState('');


    const handleAnswerChange = (e) => {
        console.log(e.target.name)
        let ansObj = {...answers};
        ansObj[e.target.name] = e.target.value;
        setAnswers(ansObj);
    }

    const handleFormSubmit = (e) => {
        console.log('submit')
        e.preventDefault()
        console.log(answers)
        console.log(questionText)
        console.log(correctAnsw)

        let questionsRef = db.collection('exams').doc('iua').collection('questions')

        questionsRef.add({answers, correctAnsw, title: questionText})
            .then((res) => {
                console.log(`Saved as id ${res.id}`)
                setResId(res.id)
                document.getElementById('submit-question-form').reset()
            })
    }

    const handleMultipleSubmit = () => {
        csvQuestions.forEach(q => {
            const thisTitle = q.Question
            const thisAnswers = {
                a1: q.Ans1,
                a2: q.Ans2,
                a3: q.Ans3,
                a4: q.Ans4,
            }
            const thisCorrectAnsw = 'a' + q.Correct

            const qObj = {
                answers: thisAnswers,
                correctAnsw: thisCorrectAnsw,
                title: thisTitle,
            }

            console.log(qObj)

            let questionsRef = db.collection('exams').doc('iua').collection('questions')

            questionsRef.add(qObj)
                .then((res) => {
                    console.log(`Saved as id ${res.id}`)
                    setResId(res.id)
                })
                .catch(err => console.error(err))


        })
    }

    return (
        <div>
            <form id="submit-question-form" onSubmit={handleFormSubmit}>
                <div>
                    <label>Question text: <input autoComplete="off" onChange={e => setQuestionText(e.target.value)} className="admin-inputs" name="question-text" type="text"/></label>
                </div>
                <div className="question-options">
                    <label>Option 1: <input name="a1" className="admin-inputs" autoComplete="off" onChange={e => handleAnswerChange(e)} type="text"/></label>
                    <label>Option 2: <input name="a2" className="admin-inputs" autoComplete="off" onChange={e => handleAnswerChange(e)} type="text"/></label>
                    <label>Option 3: <input name="a3" className="admin-inputs" autoComplete="off" onChange={e => handleAnswerChange(e)} type="text"/></label>
                    <label>Option 4: <input name="a4" className="admin-inputs" autoComplete="off" onChange={e => handleAnswerChange(e)} type="text"/></label>
                </div>
                <div className="question-correct-answer">
                    <label>
                        Correct: 
                        <select name="correct-answer" onChange={e => setCorrectAnsw(e.target.value)} className="admin-inputs">
                            <option value="a1">{answers.a1}</option>
                            <option value="a2">{answers.a2}</option>
                            <option value="a3">{answers.a3}</option>
                            <option value="a4">{answers.a4}</option>
                        </select>
                    </label>

                </div>
                <div>
                    <input type="submit"/>
                </div>
            </form>
            <div>
                <div>
                    <CSVReader parserOptions={{ header: true }} onFileLoaded={(data) => setCsvQuestions(data)} />
                </div>
                <div>
                    <button onClick={handleMultipleSubmit}>Submit CSV</button>
                </div>
            </div>
            <div>
                <p>Title: {questionText}</p>
                <p>Answers: | {answers.a1} | {answers.a2} | {answers.a3} | {answers.a4} |</p>
                <p>Correct: {correctAnsw} </p>
            </div>
            <div>
                <p>Saved as: {resId}</p>
            </div>
        </div>
    )
}

export default CreateQuestion;