import React from "react";

import moment from "moment";
import ReactTooltip from "react-tooltip";
import styles from "./CustomRow.module.css";

const CustomRow = (props) => {
  const user = props.user;

  const percentage = user.score >= 0 ? 100 * (user.score / 100) : null;

  let displayPercentage;

  if (percentage === 0) {
    displayPercentage = "0.0%";
  } else if (percentage > 0) {
    displayPercentage = percentage.toFixed(1) + "%";
  }

  const passPercent = 70;

  let passed;

  if (percentage > 0 && percentage > passPercent) {
    passed = true;
  }

  const calculateOverall = () => {
    if (!user.score) {
      return null;
    }

    if (percentage >= 85) {
      return "Distinction";
    }

    if (percentage >= 70) {
      return "Pass";
    }

    return "Fail";
  };

  return (
    <tr className={styles.users_row}>
      <td>{user.tag}</td>
      <td>{user.code}</td>
      <td data-tip={user.email}>
        <strong>{user.name}</strong>
        <ReactTooltip effect="solid" type="info" place="top" delayHide={100} />
      </td>
      <td>{user.examDuration ? user.examDuration : "-"}</td>
      <td>
        {user.used
          ? moment(user.used.toDate()).format("DD-MM-YYYY HH:mm:ss")
          : null}
      </td>
      <td>
        {!user.used && (
          <button onClick={() => props.triggerEmailUser(user.code)}>
            Email Code
          </button>
        )}
        {user.used && (
          <button onClick={() => props.resetAsUnused(user.code)}>
            Reset Code
          </button>
        )}
      </td>
      <td>
        <button
          onClick={() => props.triggerMarkExam(user.code)}
          disabled={user.resultString}
        >
          Mark Answers
        </button>
      </td>
      <td>
        {user.used ? (
          <button
            onClick={() => props.emailResults(user.code)}
            disabled={!user.resultString}
          >
            Email Results
          </button>
        ) : null}
      </td>
      <td>{user.score > -1 ? user.score : null}</td>
      <td>{user.wrongAnswers > -1 ? user.wrongAnswers : null}</td>
      <td className={passed ? styles.passedPercentage : null}>
        {displayPercentage}
      </td>
      <td>{calculateOverall()}</td>
      <td>
        <button onClick={props.downloadUser} disabled={!user.score}>
          Download
        </button>
      </td>
      <td>
        <button onClick={props.downloadSurvey} disabled={!user.score}>
          Download Survey
        </button>
      </td>
    </tr>
  );
};

export default CustomRow;
