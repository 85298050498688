import React, { useState, useEffect } from "react";
import "./App.css";

import { functions, auth, db } from "./services/firebase";

import { Route, Switch } from "react-router-dom";

import SignIn from "./components/SignIn/SignIn";
import Questions from "./components/Questions/Questions";
import CreateQuestion from "./components/CreateQuestion/CreateQuestion";
import Users from "./components/Users/Users";

function App() {
  const [user, setUser] = useState("");
  const [signInError, setSignInError] = useState(null);

  useEffect(() => {
    auth.onAuthStateChanged(function (user) {
      if (user) {
        // User is signed in.
        setUser(user);
      } else {
        // User is signed out.
        setUser(null);
      }
    });
  });

  const signInUser = (email, password) => {
    auth
      .signInWithEmailAndPassword(email, password)
      .then(setSignInError(null))
      .catch((err) => {
        console.error("Sign in error, ", err);
        setSignInError(err.message);
      });
  };

  if (!user) {
    return (
      <div>
        <SignIn error={signInError} signInUser={signInUser} />
      </div>
    );
  }

  return (
    <div>
      {/* <Navbar/> */}

      <Switch>
        <Route path="/questions" component={Questions} exact />
        <Route path="/questions/new" component={CreateQuestion} exact />
        <Route path="/users" component={Users} exact />
      </Switch>

      <div className="sign_out_container">
        <button onClick={() => auth.signOut()}>Sign Out</button>
        <span>Signed in as {user.uid}</span>
      </div>
    </div>
  );
}

export default App;
