import React from 'react';

import QuestionsRow from './QuestionsRow';

const QuestionsTable = (props) => {

    const questions = props.questions;

    return (
        <table>
            <thead>
                <tr>
                    <th className={'clickable'} onClick={() => props.setOrderBy({value:'title', direction:'asc'})}>Question</th>
                    <th className={'clickable'} onClick={() => props.setOrderBy({value:'correctAnswers', direction:'desc'})}>Correct</th>
                    <th className={'clickable'} onClick={() => props.setOrderBy({value:'incorrectAnswers', direction:'desc'})}>Incorrect</th>
                    <th>Skipped</th>
                    <th>%</th>
                </tr>
            </thead>
            <tbody>
                {questions.map(question => {
                    return (
                        <QuestionsRow key={question[0]} qId={question[0]} questionData={question[1]} numCompleted={props.numCompleted} />
                    )
                })}
            </tbody>
        </table>
    )
}

export default QuestionsTable;