import React, { useState } from 'react';

import styles from './SignIn.module.css';

const SignIn = (props) => {

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const signInUser = (e) => {
        e.preventDefault();

        props.signInUser(email, password)
    }

    return (
        <div>
            <form className={styles.adminSignInForm} onSubmit={e => signInUser(e)}>
                <div>
                    <input name="email" type="email" onChange={e => setEmail(e.target.value)}/>
                </div>
                <div>
                    <input name="password" type="password" onChange={e => setPassword(e.target.value)}/>
                </div>
                <div>
                    <input type="submit" />
                </div>
                <div>
                    <span style={{color:'red'}}>{props.error}</span>
                </div>
            </form>
        </div>
    )
}

export default SignIn;