import React, { useState, useEffect } from 'react';

import {db, functions, firestore} from '../../services/firebase';
import {ExportToCsv} from 'export-to-csv';

import QuestionsTable from './QuestionsTable';

const Questions = () => {

    const [questions, setQuestions] = useState([]);
    const [updatedQuestions, setUpdatedQuestions] = useState([]);
    const [numCompleted, setNumCompleted] = useState(0);
    const [orderBy, setOrderBy] = useState({value:'title', direction:'asc'});

    const questionsRef = db.collection('exams').doc('iua').collection('questions');
    const usersRef = db.collection('exams').doc('iua').collection('users');

    useEffect(() => {
        questionsRef.orderBy(orderBy.value, orderBy.direction).onSnapshot(snapshot => {
            let questionsSnapshot = [];
            
            snapshot.forEach(question => {
                questionsSnapshot.push([question.id, question.data()])
            })
    
            setQuestions(questionsSnapshot)
        })

        usersRef.doc('_data').onSnapshot(doc => {
            setNumCompleted(doc.data().numberCompleted)
        })
    }, [orderBy])


    useEffect(() => {
        const amendedQuestions = questions.map(question => {
            const qId = question[0];
            const qData = {...question[1]};

            const correct = qData.correctAnswers;
            const incorrect = qData.incorrectAnswers;

            const numSkipped = numCompleted - (correct || 0) - (incorrect || 0);

            const ratioCorrect = numCompleted && correct ? (correct/numCompleted) : 0;

            qData['numSkipped'] = numSkipped;
            qData['ratioCorrect'] = ratioCorrect;

            return [qId, qData]

        })

        setUpdatedQuestions(amendedQuestions)
    }, [questions, numCompleted])

    
    const handleAnalyseResults = () => {
        const analyseResults = functions.httpsCallable('analyseResults');

        analyseResults().then(res => console.log(res))
    } 



    // Delete info in the DB relating to question analysis
    const deleteAnalysis = () => {

        questionsRef.get()
            .then(snapshot => {
                snapshot.forEach(doc => {
                    doc.ref.update({
                        correctAnswers: firestore.FieldValue.delete(),
                        incorrectAnswers: firestore.FieldValue.delete()
                    })
                })
            })
        
        usersRef.doc('_data').update({
            numberCompleted: firestore.FieldValue.delete()
        })
    }


    // Download CSV of questions and their analysis
    const handleDownloadQuestions = () => {
        const qArray = updatedQuestions.map(question => {
            const qId = question[0];
            const qData = question[1];

            const qObj = {
                id: qId,
                question: qData.title,
                correct: qData.correctAnswers || 0,
                incorrect: qData.incorrectAnswers|| 0,
                skipped: qData.numSkipped || 0,
                percentage: qData.ratioCorrect
            }

            return qObj
        })

        const d = new Date()

        const filename = `Questions_Report_${d.getFullYear()}_${d.getMonth()}_${d.getDate()}_${d.getHours()}_${d.getMinutes()}_${d.getSeconds()}`

        console.log(filename)

        const csvExporter = new ExportToCsv({filename, useKeysAsHeaders: true});

        csvExporter.generateCsv(qArray)
    }

    const handleSetOrderBy = (obj) => {
        setOrderBy(obj)
    }


    return (

        <div>
            <div>
                <button onClick={deleteAnalysis}>Clear Results</button>
                <button onClick={handleAnalyseResults}>Analyse Results</button>
                <button onClick={handleDownloadQuestions}>Download Results</button>
            </div>
            <div>
                <QuestionsTable questions={updatedQuestions} numCompleted={numCompleted} setOrderBy={handleSetOrderBy}/>
            </div>
        </div>
    )
}

export default Questions;