import React, { useEffect, useState } from "react";

import { db, functions } from "../../services/firebase";
import CSVReader from "react-csv-reader";
import { ExportToCsv } from "export-to-csv";

import CustomRow from "../CustomRow/CustomRow";

import styles from "./Users.module.css";

const DEFAULT_EXAM_DURATION_IN_MINS = 120;

const usersRef = db.collection("exams").doc("iua").collection("users");

const Users = () => {
  const [newName, setNewName] = useState("");
  const [newEmail, setNewEmail] = useState("");
  const [examDuration, setExamDuration] = useState(
    DEFAULT_EXAM_DURATION_IN_MINS
  );
  const [newTag, setNewTag] = useState("");
  const [filter, setFilter] = useState("");
  const [usersCsv, setUsersCsv] = useState([]);

  const [users, setUsers] = useState([]);

  useEffect(() => {
    usersRef.orderBy("name").onSnapshot((snapshot) => {
      const allUsers = snapshot.docs.map((doc) => doc.data());
      const filteredUsers = filter
        ? allUsers.filter((user) => user.tag === filter)
        : allUsers;
      setUsers(filteredUsers);
    });
  }, [filter]);

  const createNewUser = (name, email, examDuration, tag, e) => {
    if (e) {
      e.preventDefault();
    }

    const createExamUser = functions.httpsCallable("createExamUser");

    createExamUser({
      fullName: name,
      email: email,
      tag: tag,
      examDuration: examDuration,
    })
      .then((result) => {
        console.log(result);
        setNewName("");
        setNewEmail("");
        setExamDuration(DEFAULT_EXAM_DURATION_IN_MINS);
      })
      .catch((err) => console.log(err));
  };

  // Create multiple users from CSV file
  const createMultipleUsers = () => {
    usersCsv.forEach((user) => {
      const examDuration = user.duration
        ? user.duration
        : DEFAULT_EXAM_DURATION_IN_MINS;
      createNewUser(user.name, user.email, examDuration, user.tag);
    });
  };

  const triggerEmailUser = (uid) => {
    const emailUser = functions.httpsCallable("emailUser");
    emailUser({ uid });
  };

  const triggerMarkExam = (uid) => {
    console.log(`Will mark ${uid}`);

    const markExam = functions.httpsCallable("markAnswers");

    markExam({ uid });
  };

  const triggerEmailResults = (uid) => {
    const emailResults = functions.httpsCallable("emailResults");
    emailResults({ uid });
  };

  const downloadUser = async (uid) => {
    const user = users.find((entry) => entry.code === uid);

    const answersSnapshot = await usersRef.doc(uid).collection("answers").get();

    let answers = [];
    answersSnapshot.forEach((doc) => {
      answers.push(doc.data());
    });

    const assignedQuestions = user.assignedQuestions;

    console.log("User downloaded:");
    console.log({ user, answers });

    let resultsArray = [];

    assignedQuestions.forEach((question) => {
      const id = question.id;
      const title = question.title;
      const a1 = question.answers.a1;
      const a2 = question.answers.a2;
      const a3 = question.answers.a3;
      const a4 = question.answers.a4;
      const correct = question.correctAnsw;

      const isAns = answers.find((answer) => answer.qId === id);

      const userAns = isAns ? isAns.answer : null;

      let score;

      if (isAns) {
        if (isAns.answerCorrect !== null) {
          score = isAns.answerCorrect;
        }
      } else {
        score = "";
      }

      const row = {
        id,
        title,
        a1,
        a2,
        a3,
        a4,
        correct,
        userAns: userAns || "",
        score: score,
      };

      resultsArray.push(row);
    });

    const csvExporter = new ExportToCsv({
      filename: user.name,
      showTitle: true,
      title: user.name,
      useKeysAsHeaders: true,
    });

    csvExporter.generateCsv(resultsArray);
  };

  const downloadSurvey = async (uid) => {
    const doc = await usersRef.doc(uid).get();
    const data = doc.data();
    console.log(data.resultCopy);

    const user = users.find((entry) => entry.code === uid);
    const answersSnapshot = await usersRef.doc(uid).collection("answers").get();

    let answers = [];
    answersSnapshot.forEach((doc) => {
      answers.push(doc.data());
    });

    const assignedQuestions = user.assignedQuestions;

    let resultsArray = [];

    assignedQuestions.forEach((question) => {
      const id = question.id;
      const correct = question.correctAnsw;

      const isAns = answers.find((answer) => answer.qId === id);

      const userAns = isAns ? isAns.answer : null;

      let score;

      if (isAns) {
        if (isAns.answerCorrect !== null) {
          score = isAns.answerCorrect;
        }
      } else {
        score = "";
      }

      const row = {
        id,
        correct,
        userAns: userAns || "",
        score: score,
      };

      resultsArray.push(row);
    });

    const csvExporter = new ExportToCsv({
      filename: user.name,
      showTitle: true,
      title: user.name,
      useKeysAsHeaders: true,
    });

    csvExporter.generateCsv(resultsArray);
  };

  const downloadUsers = () => {
    const userTable = users.map((user) => {
      const percentage = user.score ? user.score / 100 : 0;

      let result;

      if (percentage >= 0.85) {
        result = "Distinction";
      } else if (percentage >= 0.7) {
        result = "Pass";
      } else {
        result = "Fail";
      }

      return {
        code: user.code,
        name: user.name,
        email: user.email,
        score: user.score || 0,
        percentage: percentage,
        result: result,
      };
    });

    const d = new Date();

    const filename = `Users_Report_${d.getFullYear()}_${d.getMonth()}_${d.getDate()}_${d.getHours()}_${d.getMinutes()}_${d.getSeconds()}`;

    const csvExporter = new ExportToCsv({
      filename: filename,
      useKeysAsHeaders: true,
    });

    csvExporter.generateCsv(userTable);
  };

  const resetAsUnused = (uid) => {
    const reset = functions.httpsCallable("resetAsUnused");
    reset({ uid });
  };

  let tableEntries;

  if (users) {
    tableEntries = users.map((user) => {
      return (
        <CustomRow
          key={user.code}
          user={user}
          triggerEmailUser={() => triggerEmailUser(user.code)}
          triggerMarkExam={() => triggerMarkExam(user.code)}
          emailResults={() => triggerEmailResults(user.code)}
          downloadUser={() => downloadUser(user.code)}
          resetAsUnused={() => resetAsUnused(user.code)}
          downloadSurvey={() => downloadSurvey(user.code)}
        />
      );
    });
  }

  return (
    <div className={styles.user_container}>
      <div>
        <h4>Create a User:</h4>
        <form
          onSubmit={(e) =>
            createNewUser(newName, newEmail, examDuration, newTag, e)
          }
        >
          <div className={styles.formField}>
            <label htmlFor="name">Name:</label>
            <input
              type="text"
              name="name"
              id="candidate-name"
              value={newName}
              autoComplete="off"
              onChange={(e) => setNewName(e.target.value)}
            />
          </div>
          <div className={styles.formField}>
            <label htmlFor="email">Email:</label>
            <input
              type="email"
              name="email"
              value={newEmail}
              autoComplete="off"
              onChange={(e) => setNewEmail(e.target.value)}
            />
          </div>
          <div className={styles.formField}>
            <label htmlFor="duration">Exam Duration (mins):</label>
            <input
              type="number"
              name="duration"
              value={examDuration}
              autoComplete="off"
              onChange={(e) => setExamDuration(e.target.value)}
            />
          </div>
          <div className={styles.formField}>
            <label htmlFor="tag">Tag:</label>
            <input
              type="text"
              name="tag"
              value={newTag}
              autoComplete="off"
              onChange={(e) => setNewTag(e.target.value)}
            />
          </div>
          {/* <label htmlFor="validFromTime">Valid From (optional):</label> */}
          {/* <input type="datetime-local" name="validFromTime" onChange={(e) => setValidFromTime(e.target.value)}/> */}
          <div className={styles.formField}>
            <input type="submit" />
          </div>
        </form>
      </div>
      <hr />
      <div>
        <div>
          <h4>User table:</h4>
        </div>
        <div className={styles.formField}>
          <label htmlFor="filter">Filter by Tag:</label>
          <input
            type="text"
            name="filter"
            value={filter}
            onChange={(e) => setFilter(e.target.value)}
          />
        </div>
        <table className={styles.user_table}>
          <thead>
            <tr>
              <th>Tag</th>
              <th>Code</th>
              <th>Name</th>
              <th>Exam Duration (mins)</th>
              <th>Used</th>
              {/*<th>Start Limit</th>*/}
              <th>Email Code</th>
              <th>Mark Answers</th>
              <th>Email Results</th>
              <th>Correct</th>
              <th>Incorrect</th>
              <th>%</th>
              {/*<th>Exam 1 %</th>*/}
              <th>Overall</th>
              <th>Download</th>
              <th>Download Survey</th>
            </tr>
          </thead>
          <tbody>{tableEntries}</tbody>
        </table>

        <div className={styles.formField}>
          <button onClick={downloadUsers}>Download Report</button>
        </div>
      </div>

      <div>
        <CSVReader
          parserOptions={{ header: true }}
          onFileLoaded={(data) => setUsersCsv(data)}
        />
        <button onClick={createMultipleUsers}>Create Users</button>
      </div>
    </div>
  );
};

export default Users;
