import React from 'react';

const QuestionsRow = (props) => {

    const question = props.questionData;

    const displayPercentage = question.ratioCorrect === 0 ? '0.0%' : (question.ratioCorrect * 100).toFixed(1) + '%'

    return (
        <tr>
            <td>{question.title}</td>
            <td>{question.correctAnswers || 0}</td>
            <td>{question.incorrectAnswers || 0}</td>
            <td>{question.numSkipped || null}</td>
            <td>{displayPercentage}</td>
        </tr>
    )
}

export default QuestionsRow;